import { NextSeo } from 'next-seo'
import { useMemo, type FC } from 'react'
import { MainLayout } from '@/components/layout/MainLayout'
import { LetterCardGrid } from '@/components/letter/LetterCardGrid'
import { SlideTerms } from '@/components/term/SlideTerms'
import { useTermAPICall } from '@/lib/hooks'
import type { TermItem } from 'models/termItem'
import config from '../../../../config/config'

export const HomePage: FC = () => {
  const getTermQuery = useTermAPICall('term')
  const termData = useMemo(() => {
    if (!getTermQuery.data?.data) {
      return null
    }
    return getTermQuery.data?.data.map((item: TermItem) => {
      return {
        ...item,
        squareImage: `${config.getPublicImageDomain()}${item.squareImage}`,
        transparentImage: item.transparentImage
          ? `${config.getPublicImageDomain()}${item.transparentImage}`
          : '',
      }
    })
  }, [getTermQuery.data])

  return (
    <>
      <NextSeo
        title={'Foreign Direct Investment Glossary'}
        description={
          'Foreign Direct Investment Glossary is designed to facilitate a common understanding and harmonized use of FDI terms and definitions.'
        }
        twitter={{
          cardType: 'summary_large_image',
        }}
        openGraph={{
          title: 'Foreign Direct Investment Glossary',
          description:
            'Foreign Direct Investment Glossary is designed to facilitate a common understanding and harmonized use of FDI terms and definitions.',
          images: [
            {
              url: `https://fdiglossary.invest.gov.tr/images/home-page-background.png`,
              width: 1024,
              height: 1024,
              type: 'image/jpeg',
            },
          ],
        }}
      />
      <MainLayout>
        <section className="hero-section">
          <div className="w-layout-blockcontainer w-container container">
            <div className="hero-content-div">
              <div className="heading-set">
                <div className="heading-set-2">
                  <div className="tagline-heading">GLOSSARY</div>
                  <div className="heading-set-3">
                    <h1 className="heading-1">Foreign Direct Investment Glossary</h1>
                    <div className="description">
                      Foreign Direct Investment Glossary is designed to facilitate a common
                      understanding and harmonized use of FDI terms and definitions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sliding-cards-section">
          {termData && (
            <SlideTerms
              termData={termData.filter((t: TermItem) => {
                return t.isHighlighted
              })}
            />
          )}
        </section>
        <section className="section-large">
          <div className="container-large-2">
            <div className="w-layout-grid content-grid-b">
              <div id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04233-834f41c4">
                <div className="content-wrapper">
                  <div className="heading-set-2">
                    <div className="tagline-heading">GLOSSARY</div>
                    <div className="heading-set-3">
                      <h2 className="h3-heading">Preface</h2>
                      <div className="description">
                        The Foreign Direct Investment Glossary is an essential resource for
                        understanding the complex world of FDI for a wide audience. It promotes
                        investment literacy and supports global economic integration, empowering
                        users to understand international investments effectively.
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid content-feature-grid">
                    <div
                      id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04240-834f41c4"
                      className="content-feature-wrap"
                    >
                      <div className="icon-regular-2 w-embed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                          <path
                            d="M12 4.5C16.1423 4.5 19.5 7.85775 19.5 12C19.5 16.1423 16.1423 19.5 12 19.5C7.85775 19.5 4.5 16.1423 4.5 12C4.5 7.85775 7.85775 4.5 12 4.5ZM12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM12 8.25C12.7894 8.2503 13.5586 8.49973 14.198 8.96275C14.8374 9.42578 15.3144 10.0788 15.561 10.8287C15.8075 11.5787 15.8112 12.3873 15.5713 13.1394C15.3314 13.8915 14.8603 14.5488 14.2251 15.0175C13.5898 15.4862 12.8229 15.7425 12.0335 15.7498C11.2441 15.7572 10.4725 15.5152 9.82864 15.0584C9.18479 14.6016 8.70153 13.9533 8.4477 13.2057C8.19388 12.4582 8.18243 11.6497 8.415 10.8953C8.59044 11.2873 8.89521 11.6072 9.27829 11.8014C9.66138 11.9956 10.0995 12.0523 10.5195 11.9621C10.9394 11.8719 11.3156 11.6402 11.5851 11.3057C11.8546 10.9713 12.0011 10.5545 12 10.125C12.0001 9.76385 11.8959 9.41036 11.6999 9.10701C11.5039 8.80366 11.2245 8.56336 10.8953 8.415C11.2448 8.30775 11.6153 8.25 12 8.25Z"
                            fill="#171717"
                          ></path>
                        </svg>
                      </div>
                      <div className="wrap-v-x-small">
                        <div className="paragraph-large-2 text-weight-medium">
                          <strong className="bold-text">Comprehensive Definitions</strong>
                        </div>
                        <p className="paragraph-small">Clarity in FDI concepts.</p>
                      </div>
                    </div>
                    <div
                      id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04247-834f41c4"
                      className="content-feature-wrap"
                    >
                      <div className="icon-regular-2 w-embed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                          <path
                            d="M13.5 13.689V15.2565C12.8212 15.0165 12.0947 14.9429 11.3816 15.0418C10.6684 15.1408 9.9894 15.4094 9.40157 15.8252C8.81375 16.2409 8.33426 16.7916 8.00337 17.4311C7.67249 18.0705 7.49986 18.78 7.5 19.5L6 19.4993C5.99977 18.5834 6.20919 17.6797 6.61222 16.8573C7.01526 16.0349 7.6012 15.3157 8.32516 14.7548C9.04912 14.1939 9.89188 13.8061 10.7889 13.6212C11.6858 13.4364 12.6132 13.4593 13.5 13.6883V13.689ZM12 12.75C9.51375 12.75 7.5 10.7362 7.5 8.25C7.5 5.76375 9.51375 3.75 12 3.75C14.4862 3.75 16.5 5.76375 16.5 8.25C16.5 10.7362 14.4862 12.75 12 12.75ZM12 11.25C13.6575 11.25 15 9.9075 15 8.25C15 6.5925 13.6575 5.25 12 5.25C10.3425 5.25 9 6.5925 9 8.25C9 9.9075 10.3425 11.25 12 11.25ZM16.5 15.75V13.5H18V15.75H20.25V17.25H18V19.5H16.5V17.25H14.25V15.75H16.5Z"
                            fill="#171717"
                          ></path>
                        </svg>
                      </div>
                      <div className="wrap-v-x-small">
                        <div className="paragraph-large-2 text-weight-medium">
                          <strong className="bold-text">Educational Tool</strong>
                        </div>
                        <p className="paragraph-small">Enhances investment literacy.</p>
                      </div>
                    </div>
                    <div
                      id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c0424e-834f41c4"
                      className="content-feature-wrap"
                    >
                      <div className="icon-regular-2 w-embed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                          <path
                            d="M11.25 4.53749V6.80249C8.706 7.16774 6.75 9.35624 6.75 12C6.75 14.8995 9.1005 17.25 12 17.25C13.179 17.25 14.268 16.8615 15.144 16.2045L16.7467 17.8072C15.4538 18.8647 13.8 19.5 12 19.5C7.85775 19.5 4.5 16.1422 4.5 12C4.5 8.11124 7.46025 4.91324 11.25 4.53749ZM19.4625 12.75C19.3125 14.2582 18.717 15.6352 17.8073 16.746L16.2045 15.144C16.7198 14.457 17.0693 13.6395 17.1968 12.75H19.4633H19.4625ZM12.7515 4.53749C16.2945 4.88924 19.1115 7.70699 19.464 11.25H17.1975C16.869 8.95124 15.0503 7.13249 12.7515 6.80324V4.53674V4.53749Z"
                            fill="#171717"
                          ></path>
                        </svg>
                      </div>
                      <div className="wrap-v-x-small">
                        <div className="paragraph-large-2 text-weight-medium">
                          <strong className="bold-text">Global Scope</strong>
                        </div>
                        <p className="paragraph-small">Promotes economic integration.</p>
                      </div>
                    </div>
                    <div
                      id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04255-834f41c4"
                      className="content-feature-wrap"
                    >
                      <div className="icon-regular-2 w-embed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                          <path
                            d="M16.3807 14.7443L16.5128 14.877L16.6462 14.7443C16.8029 14.5876 16.989 14.4632 17.1937 14.3784C17.3985 14.2936 17.6179 14.25 17.8395 14.25C18.0611 14.25 18.2805 14.2936 18.4853 14.3784C18.69 14.4632 18.8761 14.5876 19.0328 14.7443C19.1895 14.901 19.3138 15.087 19.3986 15.2917C19.4834 15.4965 19.527 15.7159 19.527 15.9375C19.527 16.1591 19.4834 16.3785 19.3986 16.5833C19.3138 16.788 19.1895 16.974 19.0328 17.1307L16.5128 19.65L13.9943 17.1307C13.6778 16.8143 13.5 16.3851 13.5 15.9375C13.5 15.4899 13.6778 15.0607 13.9943 14.7443C14.3107 14.4278 14.7399 14.25 15.1875 14.25C15.6351 14.25 16.0643 14.4278 16.3807 14.7443ZM12 13.5V15C10.8065 15 9.66193 15.4741 8.81802 16.318C7.97411 17.1619 7.5 18.3065 7.5 19.5H6C6.00002 17.9412 6.6067 16.4436 7.6916 15.3242C8.7765 14.2049 10.2544 13.5517 11.8125 13.503L12 13.5ZM12 3.75C14.4862 3.75 16.5 5.76375 16.5 8.25C16.5004 9.4144 16.0493 10.5336 15.2416 11.3723C14.4338 12.2109 13.3323 12.7037 12.1688 12.747L12 12.75C9.51375 12.75 7.5 10.7362 7.5 8.25C7.49958 7.0856 7.95068 5.96641 8.75843 5.12774C9.56618 4.28908 10.6677 3.79629 11.8312 3.753L12 3.75ZM12 5.25C10.3425 5.25 9 6.5925 9 8.25C9 9.9075 10.3425 11.25 12 11.25C13.6575 11.25 15 9.9075 15 8.25C15 6.5925 13.6575 5.25 12 5.25Z"
                            fill="#171717"
                          ></path>
                        </svg>
                      </div>
                      <div className="wrap-v-x-small">
                        <div className="paragraph-large-2 text-weight-medium">
                          <strong className="bold-text">Stakeholder Engagement</strong>
                        </div>
                        <p className="paragraph-small">Facilitates dialogue, cooperation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-bcc1bd44-2395-603a-ef7b-8b6f77c0425c-834f41c4"
                className="content-block"
              >
                <img src="images/FDI-Glossary-Logo.svg" loading="lazy" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="content-section">
          <div className="w-layout-blockcontainer w-container container flex">
            <div className="heading-set">
              <div className="heading-set-2">
                <div className="tagline-heading">GLOSSARY</div>
                <div className="heading-set-3">
                  <h2 className="h3-heading">Featured Terms</h2>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </div>
                </div>
              </div>
            </div>
            <div className="vertical-flex">
              {termData && (
                <FeaturedTerms
                  termData={termData.filter((t: TermItem) => {
                    return t.isFeatured
                  })}
                />
              )}
            </div>
          </div>
        </section> */}
        <section className="dynamic-cards-section p0-100">
          <div className="w-layout-blockcontainer w-container container flex">
            <div className="heading-set">
              <div className="heading-set-2">
                <div className="tagline-heading">GLOSSARY</div>
                <div className="heading-set-3">
                  <h2 className="h3-heading">Jump to Letter</h2>
                </div>
              </div>
            </div>
            <LetterCardGrid />
          </div>
        </section>
      </MainLayout>
    </>
  )
}
