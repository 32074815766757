import { useMemo, type FC } from 'react'
import { useLetterAPICall } from '@/lib/hooks'

export const LetterCardGrid: FC = () => {
  const getLetterQuery = useLetterAPICall('letter')
  const letterData = useMemo(() => {
    if (!getLetterQuery.data?.data) {
      return null
    }
    return getLetterQuery.data?.data
  }, [getLetterQuery.data])

  return (
    <div className="w-dyn-list">
      <div role="list" className="letter-card-grid w-dyn-items">
        {letterData?.map((item, index) => {
          return (
            <div id={`w-node-${index}`} role="listitem" className="w-dyn-item" key={index}>
              <a
                id={item.name}
                data-linkx={item.name}
                href={`/list?letters=${item.name}`}
                className="link-block full-width w-inline-block"
              >
                <div
                  data-link={item.name}
                  data-w-id="03af0dd4-ebd4-4c0b-8bbe-275ced51d757"
                  className="letter-card"
                  style={{ backgroundColor: item.colorCode }}
                >
                  <h2 className="letter-heading">{item.name.toUpperCase()}</h2>
                  <div className="card-overlay base hidden"></div>
                </div>
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}
