import { useQuery } from 'react-query'
import { API } from './api'

export function useTermAPICall(name: string) {
  return useQuery(name, () => API.getTerms())
}

export function useLetterAPICall(name: string) {
  return useQuery(name, () => API.getLetters())
}
