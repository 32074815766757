import { type FC } from 'react'
import type { TermItem } from 'models/termItem'
import { ThemeType } from 'models/themeType'

export interface ISlideTermsProps {
  termData: TermItem[]
}

export const SlideTerms: FC<ISlideTermsProps> = ({ termData }) => {
  return (
    <div className="marquee-wrapper">
      <div className="marquee-track">
        <div className="collection-list-wrapper w-dyn-list">
          <div role="list" className="marquee-list w-dyn-items">
            {termData?.map((item: TermItem, index: number) => {
              return (
                <div role="listitem" className="marquee-item w-dyn-item" key={index}>
                  <a href={`/glossary/${item.slug}`} className="link-block w-inline-block">
                    <div
                      data-w-id="d43cb81f-6433-3de5-bb18-800feacbee60"
                      style={{
                        backgroundColor: item.colorCode,
                        backgroundImage: `url("${item.squareImage}")`,
                      }}
                      className="glossary-card"
                    >
                      <div
                        className={`card-overlay cream ${
                          item.theme === ThemeType.cream ? '' : 'w-condition-invisible'
                        }`}
                      ></div>
                      <div
                        className={`card-overlay blue ${
                          item.theme === ThemeType.lightBlue ? '' : 'w-condition-invisible'
                        }`}
                      ></div>
                      <div
                        className={`card-overlay red ${
                          item.theme === ThemeType.lightRed ? '' : 'w-condition-invisible'
                        }`}
                      ></div>
                      <div
                        className={`card-overlay trqois ${
                          item.theme === ThemeType.turqoise ? '' : 'w-condition-invisible'
                        }`}
                      ></div>
                      <h2 className="card-heading">{item.name}</h2>
                      <div className="card-overlay base hidden"></div>
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
